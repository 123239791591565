@font-face {
    font-family: 'SF Automaton Condensed';
    src: url('SFAutomatonCondensed-Oblique.woff2') format('woff2'),
        url('SFAutomatonCondensed-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Automaton Extended';
    src: url('SFAutomatonExtended.woff2') format('woff2'),
        url('SFAutomatonExtended.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Automaton Condensed';
    src: url('SFAutomatonCondensed.woff2') format('woff2'),
        url('SFAutomatonCondensed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Automaton';
    src: url('SFAutomaton-Bold.woff2') format('woff2'),
        url('SFAutomaton-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Automaton';
    src: url('SFAutomaton-BoldOblique.woff2') format('woff2'),
        url('SFAutomaton-BoldOblique.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Automaton Extended';
    src: url('SFAutomatonExtended-Oblique.woff2') format('woff2'),
        url('SFAutomatonExtended-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Automaton';
    src: url('SFAutomaton.woff2') format('woff2'),
        url('SFAutomaton.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Automaton';
    src: url('SFAutomaton-Oblique.woff2') format('woff2'),
        url('SFAutomaton-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

